/** CATEGORIES **/
/* eslint-disable */
import { Action } from "redux";
import { IProduct } from "../../interfaces";

export enum ProductActionTypes {
  CREATE_PRODUCT = 'CREATE_PRODUCT',
  CREATE_PRODUCT_FAILED = 'CREATE_PRODUCT_FAILED',
  CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS',

  READ_PRODUCTS_BY_CATEGORY = 'READ_PRODUCTS_BY_CATEGORY',
  READ_PRODUCTS_BY_CATEGORY_FAILED = 'READ_PRODUCTS_BY_CATEGORY_FAILED',
  READ_PRODUCTS_BY_CATEGORY_SUCCESS = 'READ_PRODUCTS_BY_CATEGORY_SUCCESS',

  UPDATE_PRODUCT = "UPDATE_PRODUCT",
  UPDATE_PRODUCT_FAILED = "UPDATE_PRODUCT_FAILED",
  UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS",

  DELETE_PRODUCT = "DELETE_PRODUCT",
  DELETE_PRODUCT_FAILED = "DELETE_PRODUCT_FAILED",
  DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS",

  UPLOAD_IMAGE_PRODUCT = "UPLOAD_IMAGE_PRODUCT",
  UPLOAD_IMAGE_PRODUCT_FAILED = "UPLOAD_IMAGE_PRODUCT_FAILED",
  UPLOAD_IMAGE_PRODUCT_SUCCESS = "UPLOAD_IMAGE_PRODUCT_SUCCESS"
}

export interface IProductAction extends Action {
  _id?: string;
  categoryID?: string;
  type: ProductActionTypes;
  request?: Partial<IProduct>;
  response?: IProduct | IProduct[] | [] | string;
}
