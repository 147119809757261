import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import React, { Suspense, Component } from "react";

const Topbar = React.lazy(() => import("./Topbar"));
const LeftSidebar = React.lazy(() => import("./LeftSidebar"));
const loading = () => <div className="text-center"/>;

class DashboardLayout extends Component {

  constructor(props) {
    super(props);

    this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.state = {
      isCondensed: false
    }
  }

  signOut(e) {
    e.preventDefault();
    this.props.history.push("/login");
  }

  /**
   * toggle Menu
   */
  toggleMenu = (e) => {
    e.preventDefault();
    this.setState({ isCondensed: !this.state.isCondensed });
  };

  /**
   * Toggle right side bar
   */
  toggleRightSidebar = () => {
    document.body.classList.toggle("right-bar-enabled");
  };

  getPageName = () => {

  };

  render() {

    // get the child view which we would like to render
    const children = this.props.children || null;
    return (
      <div id="wrapper">
        <Suspense fallback={loading()}>
          <LeftSidebar isCondensed={false} hideLogo={false} />
        </Suspense>

        <div className="content-page">

          <Suspense fallback={loading()}>
            <Topbar rightSidebarToggle={this.toggleRightSidebar} menuToggle={this.toggleMenu}/>
          </Suspense>

          <div className="content my-2">
            <Container fluid>
              <Suspense fallback={loading()}>

                {children}
              </Suspense>
            </Container>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    user: state.Auth.user
  }
};
export default connect(mapStateToProps, null)(DashboardLayout);
