import { ICategory } from "../../interfaces";
import { ICategoryAction, CategoryActionTypes } from './constants';

interface State {
  isFetching: boolean;
  error: undefined | string;
  categories: ICategory[] | [];
}

const INIT_STATE: State = {
  isFetching: false,
  error: undefined,
  categories: [],
};

export const ReadCategories = (state: State = INIT_STATE, action: ICategoryAction) => {
  switch (action.type) {
    case CategoryActionTypes.READ_ALL_CATEGORIES:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        categories: [],
      };
    case CategoryActionTypes.READ_ALL_CATEGORIES_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.response,
      };
    case CategoryActionTypes.READ_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        categories: [...action.response as ICategory[]],
      };
    default:
      return { ...state };
  }
};
