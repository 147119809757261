import axios, { AxiosError, AxiosRequestConfig } from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    token: process.env.REACT_APP_API_TOKEN,
  }
});

axiosInstance.interceptors.request.use(
  config => {
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    const axiosError = error as AxiosError;

    if (axiosError.response) {
      const responseData: unknown = axiosError.response?.data;

      return Promise.reject(responseData);
    }

    return Promise.reject(error);
  },
);

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  return await axiosInstance.get(url, { ...config });
};

export default axiosInstance;
