import React from "react";

import Router from "./routes/sections";

interface IRoutesProps {}
const App: React.FC<IRoutesProps> = (props) => {

  return (
    <Router />
  );
}

export default App
