import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import DefaultLayout from "../../layout/DefaultLayout";
import { SplashScreen } from '../../components/loading-screen';

// ----------------------------------------------------------------------

const Page500 = lazy(() => import('../../pages/error/Error500'));
const Page404 = lazy(() => import('../../pages/error/Error404'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <DefaultLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </DefaultLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
    ],
  },
];
