import { all, put, call, fork, takeEvery } from 'redux-saga/effects';

import fetchJSON from '../../helpers/external-request'
import {
  CREATE_COLONY,
  READ_COLONIES,
  UPDATE_COLONY,
  DELETE_COLONY
} from '../../constants/actionTypes';
import {
  createColonyFailed,
  readColoniesFailed,
  updateColonyFailed,
  deleteColonyFailed,
  createColonySuccess,
  readColoniesSuccess,
  updateColonySuccess,
  deleteColonySuccess
} from './actions';

function* createColony({ payload: data }) {

  const options = {
    data,
    method: 'POST',
  };

  try {
    const response = yield call(fetchJSON, `colonies`, options);
    yield put(createColonySuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }
    yield put(createColonyFailed(message));
  }
}

function* readColonies({ payload: data }) {

  const options = {
    data,
    method: 'GET',
  };

  try {
    const response = yield call(fetchJSON, `colonies`, options);
    yield put(readColoniesSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }
    yield put(readColoniesFailed(message));
  }
}

function* updateColony({ _id, payload: data }) {

  const options = {
    data,
    method: 'PUT',
  };

  try {
    const response = yield call(fetchJSON, `colonies/${_id}`, options);
    yield put(updateColonySuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }
    yield put(updateColonyFailed(message));
  }
}

function* deleteColony({ _id, payload: data }) {

  const options = {
    method: 'DELETE',
  };

  try {
    const response = yield call(fetchJSON, `colonies/${_id}`, options);
    yield put(deleteColonySuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }
    yield put(deleteColonyFailed(message));
  }
}

export function* watchCreateProduct(): any {
  yield takeEvery(CREATE_COLONY, createColony);
}

export function* watchReadAllProducts(): any {
  yield takeEvery(READ_COLONIES, readColonies);
}

export function* watchUpdateProduct(): any {
  yield takeEvery(UPDATE_COLONY, updateColony);
}

export function* watchDeleteProduct(): any {
  yield takeEvery(DELETE_COLONY, deleteColony);
}

function* colonySaga(): any {
  yield all([
    fork(watchCreateProduct),
    fork(watchReadAllProducts),
    fork(watchUpdateProduct),
    fork(watchDeleteProduct),
  ]);
}

export default colonySaga;
