import {
  UPDATE_USER_ORDER,
  READ_ALL_USER_ORDERS,
  UPDATE_USER_ORDER_FAILED,

  UPDATE_USER_ORDER_SUCCESS,
  READ_ALL_USER_ORDERS_FAILED,
  READ_ALL_USER_ORDERS_SUCCESS,
} from '../../constants/actionTypes';

export const readAllUserOrders = payload => ({ payload, type: READ_ALL_USER_ORDERS });
export const readAllUserOrdersFailed = payload => ({ payload, type: READ_ALL_USER_ORDERS_FAILED });
export const readAllUserOrdersSuccess = payload => ({ payload, type: READ_ALL_USER_ORDERS_SUCCESS });

export const updateUserOrderFailed = payload => ({ payload, type: UPDATE_USER_ORDER_FAILED });
export const updateUserOrderSuccess = payload => ({ payload, type: UPDATE_USER_ORDER_SUCCESS });
export const updateUserOrder = (orderID, payload) => ({ payload, orderID, type: UPDATE_USER_ORDER });
