/** CATEGORIES **/
/* eslint-disable */
import {ICategory} from "../../interfaces";

export enum CategoryActionTypes {
  CREATE_CATEGORY = 'CREATE_CATEGORY',
  CREATE_CATEGORY_FAILED = 'CREATE_CATEGORY_FAILED',
  CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS',

  READ_ALL_CATEGORIES = 'READ_ALL_CATEGORIES',
  READ_ALL_CATEGORIES_FAILED = 'READ_ALL_CATEGORIES_FAILED',
  READ_ALL_CATEGORIES_SUCCESS = 'READ_ALL_CATEGORIES_SUCCESS',

  UPDATE_CATEGORY = 'UPDATE_CATEGORY',
  UPDATE_CATEGORY_FAILED = 'UPDATE_CATEGORY_FAILED',
  UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS',

  DELETE_CATEGORY = 'DELETE_CATEGORY',
  DELETE_CATEGORY_FAILED = 'DELETE_CATEGORY_FAILED',
  DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
}
export interface ICategoryAction {
  _id?: string;
  type: CategoryActionTypes;
  payload?: {
    _id?: string;
    data?: ICategory | ICategory[];
    error?: string;
  };
  request?: Partial<ICategory>;
  response?: ICategory | ICategory[] | [] |  string | undefined;
}
