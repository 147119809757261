import React, { useState, useEffect, useCallback } from 'react';

import { paths } from '../paths';
import { useRouter } from '../hooks';
import { LoadingScreen } from "../../components/loading-screen";
import { useMonteflor } from '../../hooks/useMonteflor.context';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Readonly<Props>) {
  const { isLoading } = useMonteflor();

  return <>{isLoading ? <LoadingScreen /> : <Container>{children}</Container>}</>;
}

// ----------------------------------------------------------------------

function Container({ children }: Props) {
  const router = useRouter();

  const { isAuthenticated } = useMonteflor();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!isAuthenticated) {

      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();

      const href = `${paths.auth.login}?${searchParams}`;

      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [isAuthenticated, router]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
