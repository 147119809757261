import { toast } from "react-toastify";
import React, { useCallback } from 'react';

import { IUser } from "../entities";
import { paths } from "../routes/paths";
import { ILoginRequest } from "../dtos";
import { useRouter } from "../routes/hooks";
import apiRequest from '../helpers/external-request';
import { getCookie, setCookie, clearCookies } from "../helpers/browser-cookie-manager";

export const USER_KEY = '@@monteflor-user';
export const TOKEN_KEY = '@@monteflor-token';

export interface IAuthHook {
  isLoading: boolean;
  currentUser?: IUser;
  isAuthenticated: boolean;
  handleLogout: () => void;
  getAuthState: () => Promise<void>;
  handleLogin: (payload: ILoginRequest) => Promise<void>;
}

const useAuthentication = (): IAuthHook => {
  const router = useRouter();

  const [currentUser, setCurrentUser] = React.useState<IUser>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);

  const getAuthState = async (): Promise<void> => {
    try {
      setIsLoading(true);

      const token = getCookie(TOKEN_KEY);
      const userStored = getCookie(USER_KEY);

      if (token && userStored) {
        const user = JSON.parse(userStored) as IUser;
        const firstNameInitial = user.firstName?.charAt(0).toUpperCase();
        const lastNameInitial = user.lastName?.charAt(0).toUpperCase();
        setCurrentUser({ ...user, initials: `${firstNameInitial}${lastNameInitial}` });

        await saveCachedUserToken({ token, ...user });
      } else {
        setIsAuthenticated(false);

        const searchParams = new URLSearchParams({
          returnTo: window.location.pathname,
        }).toString();

        const href = `${paths.auth.login}?${searchParams}`;

        router.replace(href);

      }
    } catch (error) {
      const { message } = error as Error;

      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  const saveCachedUserToken = async (data: IUser & { token: string }): Promise<void> => {
    try {
      const { token, ...user } = data;

      setCookie(TOKEN_KEY, token);
      setCookie(USER_KEY, JSON.stringify(user));

      apiRequest.defaults.headers.common.Authorization = `Bearer ${token}`;

      setIsAuthenticated(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogin = async (payload: ILoginRequest): Promise<void> => {
    try {
      setIsLoading(true);
      const data = (await apiRequest.post('auth/login', payload)) as IUser & { token: string };
      await saveCachedUserToken(data);
    } catch (error) {
      const { message } = error as Error;

      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = useCallback(async () => {
    try {
      setIsLoading(true);

      clearCookies()

      apiRequest.defaults.headers.common.Authorization = undefined;

      setIsAuthenticated(false);

      const href = `${paths.auth.login}`;

      router.replace(href);

    } finally {
      setIsLoading(false);
    }
  }, []);

  const memoizedValue = React.useMemo(
    () => ({
      isLoading,
      currentUser,
      isAuthenticated,
    }),
    [isLoading, currentUser, isAuthenticated],
  );

  return {
    ...memoizedValue,
    handleLogin,
    getAuthState,
    handleLogout,
  };
};

export default useAuthentication;
