import {
  LOGIN_USER,
  LOGOUT_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESS,

  GET_USER_INFORMATION,
  UPDATE_USER_PASSWORD,
  GET_USER_INFORMATION_FAILED,

  UPDATE_USER_PASSWORD_FAILED,
  GET_USER_INFORMATION_SUCCESS,
  UPDATE_USER_PASSWORD_SUCCESS
} from '../../constants/actionTypes';

const INIT_STATE = {
  loading: false,
  error: undefined,
  user: undefined,
};

export const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true
      };
    case LOGIN_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case LOGIN_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case LOGOUT_USER:
      return { ...state, user: null };
    default:
      return { ...state };
  }
};

export const GetUserInformation = (state = INIT_STATE, { type, user, error }) => {
  switch (type) {
    case GET_USER_INFORMATION:
      return {
        loading: true,
        user: undefined,
        error: undefined
      };
    case GET_USER_INFORMATION_SUCCESS:
      return {
        user,
        loading: false,
        error: undefined
      };
    case GET_USER_INFORMATION_FAILED:
      return {
        error,
        loading: false,
        user: undefined
      };
    default:
      return { ...state };
  }
};
export const UpdateUserPassword = (state = INIT_STATE, { type, error, message }) => {
  switch (type) {
    case UPDATE_USER_PASSWORD:
      return {
        loading: true,
        user: undefined,
        error: undefined
      };
    case UPDATE_USER_PASSWORD_SUCCESS:
      return {
        user: message,
        loading: false,
        error: undefined
      };
    case UPDATE_USER_PASSWORD_FAILED:
      return {
        error,
        loading: false,
        user: undefined
      };
    default:
      return { ...state };
  }
};
