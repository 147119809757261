import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

//import "./i18n";

import 'react-toastify/dist/ReactToastify.css';

import App from "./App";
import './assets/scss/app.scss';
import "./assets/scss/Icons.scss";
import { configureAppStore } from "./redux/store";
import MonteflorProvider from "./hooks/useMonteflor.context";

const store = configureAppStore();
const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <Provider store={store}>
    <BrowserRouter basename={"/"}>
      <MonteflorProvider>
        <App />
      </MonteflorProvider>
    </BrowserRouter>

  </Provider>
);
