import { SagaIterator } from "@redux-saga/core";
import { all, put, call, fork, takeEvery } from 'redux-saga/effects';

import externalRequest from '../../helpers/external-request'
import { IProductAction, ProductActionTypes } from './constants';
import { readProductsByCategoryFailed, readProductsByCategorySuccess } from './actions';

function* readProductsByCategory({ categoryID, request: data }: IProductAction): SagaIterator {

  const options = {
    data,
    method: 'GET',
  };

  try {
    const response = yield call(externalRequest, `categories/${categoryID}/products`, options);

    yield put(readProductsByCategorySuccess(response));
  } catch (error) {
    const { message }  = error as Error;

    yield put(readProductsByCategoryFailed(message));
  }
}
export function* watchReadProductsByCategory() {
  yield takeEvery(ProductActionTypes.READ_PRODUCTS_BY_CATEGORY, readProductsByCategory);
}

function* productsSaga() {
  yield all([
    fork(watchReadProductsByCategory),
  ]);
}

export default productsSaga;
