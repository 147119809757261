import { IProduct } from '../../interfaces';
import { IProductAction, ProductActionTypes } from './constants';

export const readProductsByCategory = (categoryID: string): IProductAction => ({
  categoryID,
  type: ProductActionTypes.READ_PRODUCTS_BY_CATEGORY
});
export const readProductsByCategorySuccess = (response: IProduct[]): IProductAction => ({
  response,
  type: ProductActionTypes.READ_PRODUCTS_BY_CATEGORY_SUCCESS
});
export const readProductsByCategoryFailed = (response: string): IProductAction => ({
  response,
  type: ProductActionTypes.READ_PRODUCTS_BY_CATEGORY_FAILED
});
