import {
  CREATE_COLONY,
  READ_COLONIES,
  UPDATE_COLONY,

  DELETE_COLONY,
  CREATE_COLONY_FAILED,
  READ_COLONIES_FAILED,

  UPDATE_COLONY_FAILED,
  DELETE_COLONY_FAILED,
  CREATE_COLONY_SUCCESS,

  READ_COLONIES_SUCCESS,
  UPDATE_COLONY_SUCCESS,
  DELETE_COLONY_SUCCESS

} from '../../constants/actionTypes';

const INIT_CREATE_COLONY_STATE = {
  loading: false,
  error: undefined,
  colony: undefined,
};
const INIT_READ_COLONIES_STATE = {
  loading: false,
  error: undefined,
  colonies: undefined,
};
const INIT_UPDATE_COLONY_STATE = {
  loading: false,
  error: undefined,
  colony: undefined,
};
const INIT_DELETE_COLONY_STATE = {
  loading: false,
  error: undefined,
  colony: undefined,
};

export const CreateColony = (state = INIT_CREATE_COLONY_STATE, { type, payload }) => {
  switch (type) {
    case CREATE_COLONY:
      return {
        loading: true,
        error: undefined,
        colony: undefined,
      };
    case CREATE_COLONY_FAILED:
      return {
        loading: false,
        error: payload,
        colony: undefined,
      };
    case CREATE_COLONY_SUCCESS:
      return {
        loading: false,
        error: undefined,
        colony: payload,
      };
    default:
      return { ...state }
  }
};
export const ReadColonies = (state = INIT_READ_COLONIES_STATE, { type, payload }) => {
  switch (type) {
    case READ_COLONIES:
      return {
        loading: true,
        error: undefined,
        colonies: undefined,
      };
    case READ_COLONIES_FAILED:
      return {
        loading: false,
        error: payload,
        colonies: undefined
      };
    case READ_COLONIES_SUCCESS:
      return {
        loading: false,
        error: undefined,
        colonies: payload,
      };
    default:
      return { ...state };
  }
};
export const UpdateColony = (state = INIT_UPDATE_COLONY_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_COLONY:
      return {
        loading: true,
        error: undefined,
        colony: undefined,
      };
    case UPDATE_COLONY_FAILED:
      return {
        loading: false,
        error: payload,
        colony: undefined
      };
    case UPDATE_COLONY_SUCCESS:
      return {
        loading: false,
        error: undefined,
        colony: payload,
      };
    default:
      return { ...state };
  }
};
export const DeleteColony = (state = INIT_DELETE_COLONY_STATE, { type, payload }) => {
  switch (type) {
    case DELETE_COLONY:
      return {
        loading: true,
        error: undefined,
        colony: undefined,
      };
    case DELETE_COLONY_FAILED:
      return {
        loading: false,
        error: payload,
        colony: undefined
      };
    case DELETE_COLONY_SUCCESS:
      return {
        loading: false,
        error: undefined,
        colony: payload,
      };
    default:
      return { ...state };
  }
};
