import { SagaIterator } from "@redux-saga/core";
import { all, put, call, fork, takeEvery } from 'redux-saga/effects';

import externalRequest from '../../helpers/external-request'
import { ICategoryAction, CategoryActionTypes } from './constants';
import {
  readAllCategoriesFailed,
  readAllCategoriesSuccess,
} from './actions';

function* readAllCategories(payload: ICategoryAction): SagaIterator {
  const { request } = payload;

  const options = {
    params: {
      ...request,
      destiny: "panel"
    },
    method: 'GET',
  };

  try {
    const response = yield call(externalRequest, `categories`, options);

    yield put(readAllCategoriesSuccess(response));
  } catch (error) {
    console.log(error)
    const { message }  = error as Error;

    yield put(readAllCategoriesFailed(message));
  }
}

export function* watchReadAllCategories() {
  yield takeEvery(CategoryActionTypes.READ_ALL_CATEGORIES, readAllCategories);
}

function* categoriesSaga() {
  yield all([
    fork(watchReadAllCategories),
  ]);
}

export default categoriesSaga;
