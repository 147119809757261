import { Cookies } from "react-cookie";
import { all, put, call, fork, takeEvery } from 'redux-saga/effects';

import fetchJSON from '../../helpers/external-request'
import {
  LOGIN_USER,
  LOGOUT_USER,
  UPDATE_USER_PASSWORD, GET_USER_INFORMATION,
} from '../../constants/actionTypes';
import {
  loginUserFailed,
  loginUserSuccess,
  updateUserPasswordFailed,
  getUserInformationFailed,
  updateUserPasswordSuccess,
  getUserInformationSuccess,
} from './actions';

/**
 * Sets the session
 * @param {*} user
 */
const setSession = (user) => {
  let cookies = new Cookies();
  if (user)
    cookies.set("user", JSON.stringify(user), { path: "/" });
  else
    cookies.remove("user");
};

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload }) {
  const options = {
    data: payload,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  };

  try {
    const response = yield call(fetchJSON, 'auth/login', options);

    setSession(response);

    yield put(loginUserSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }

    yield put(loginUserFailed(message));

    setSession(null);
  }
}

function* getUserInformation({ hash }) {
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  };

  try {
    const response = yield call(fetchJSON, `app/auth/reset/${hash}`, options);

    yield put(getUserInformationSuccess(response));
  } catch ({ message }) {

    yield put(getUserInformationFailed(message));

    setSession(null);
  }
}
function* updateUserPassword({ hash, payload }) {
  const options = {
    data: payload,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  };

  try {
    const response = yield call(fetchJSON, `app/auth/reset/${hash}`, options);

    yield put(updateUserPasswordSuccess(response));
  } catch ({ message }) {

    yield put(updateUserPasswordFailed(message));

    setSession(null);
  }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
  try {
    setSession(null);
    yield call(() => {
      history.push("/login");
    });
  } catch (error) {
    //empty
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, login);
}
export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}
export function* watchGetUserInformation() {
  yield takeEvery(GET_USER_INFORMATION, getUserInformation)
}
export function* watchUpdateUserPassword() {
  yield takeEvery(UPDATE_USER_PASSWORD, updateUserPassword)
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchGetUserInformation),
    fork(watchUpdateUserPassword)
  ]);
}

export default authSaga;
