import { IProduct } from "../../interfaces";
import { IProductAction, ProductActionTypes } from './constants';

interface State {
  isFetching: boolean;
  error: undefined | string;
  products: IProduct[] | [];
}

const INIT_STATE = {
  error: undefined,
  isFetching: false,
  products: [],
};

export const ReadProductsByCategory = (state: State = INIT_STATE, action: IProductAction) => {
  switch (action.type) {
    case ProductActionTypes.READ_PRODUCTS_BY_CATEGORY:
      return {
        ...state,
        isFetching: false,
        error: undefined,
      };
    case ProductActionTypes.READ_PRODUCTS_BY_CATEGORY_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.response,
      };
    case ProductActionTypes.READ_PRODUCTS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        products: [...action.response as IProduct[]],
      };
    default:
      return { ...state };
  }
};
