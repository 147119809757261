import {
  READ_ALL_USERS,
  READ_ALL_USERS_FAILED,
  READ_ALL_USERS_SUCCESS,
} from '../../constants/actionTypes';

export const readAllUsers = payload => ({ payload, type: READ_ALL_USERS });
export const readAllUsersFailed = payload => ({ payload, type: READ_ALL_USERS_FAILED });
export const readAllUsersSuccess = payload => ({ payload, type: READ_ALL_USERS_SUCCESS });

