import {
  READ_ALL_USERS,
  READ_ALL_USERS_FAILED,
  READ_ALL_USERS_SUCCESS,
} from '../../constants/actionTypes';

const INIT_READ_ALL_USERS_STATE = {
  loading: false,
  error: undefined,
  users: undefined,
};

export const ReadAllUsers = (state = INIT_READ_ALL_USERS_STATE, { type, payload }) => {
  switch (type) {
    case READ_ALL_USERS:
      return {
        loading: true,
        error: undefined,
        users: undefined
      };
    case READ_ALL_USERS_FAILED:
      return {
        loading: false,
        error: payload,
        users: undefined
      };
    case READ_ALL_USERS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        users: payload,
      };
    default:
      return { ...state };
  }
};
