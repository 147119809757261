import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import usersSaga from './users/saga'
import coloniesSaga from './colonies/saga'
import productsSaga from './products/saga'
import userOrdersSaga from './userOrders/saga'
import categoriesSaga from "./categories/saga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    usersSaga(),
    coloniesSaga(),
    productsSaga(),
    categoriesSaga(),
    userOrdersSaga(),
  ]);
}
