import { combineReducers } from 'redux';

import * as Auth from './auth/reducers';
import * as Users from './users/reducers'
import * as Colonies from './colonies/reducers'
import * as Products from './products/reducers'
import * as Categories from './categories/reducers'
import * as UserOrders from './userOrders/reducers'

export default combineReducers({
  ...Auth,
  ...Users,
  ...Colonies,
  ...Products,
  ...Categories,
  ...UserOrders,
});
