import React from 'react';
import { Row, Col, Spinner, Container } from 'react-bootstrap';

export default function LoadingScreen({ ...other }) {
  return (
    <Container
      fluid
      className={`d-flex align-items-center justify-content-center`}
      {...other}
    >
      <Row className="w-100">
        <Col className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Col>
      </Row>
    </Container>
  );
}
