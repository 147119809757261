import { all, put, call, fork, takeEvery } from 'redux-saga/effects';

import fetchJSON from '../../helpers/external-request'
import {
  READ_ALL_USERS,
} from '../../constants/actionTypes';
import {
  readAllUsersFailed,
  readAllUsersSuccess
} from './actions';

function* readAllUsers({ payload: params }) {
  const options = {
    params,
    method: 'GET',
  };

  try {
    const response = yield call(fetchJSON, 'customers', options);
    yield put(readAllUsersSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }
    yield put(readAllUsersFailed(message));
  }
}

export function* watchReadAllUsers() {
  yield takeEvery(READ_ALL_USERS, readAllUsers);
}

function* usersSaga() {
  yield all([
    fork(watchReadAllUsers),
  ]);
}

export default usersSaga;
