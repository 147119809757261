import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { AuthGuard } from "../guards";
import DashboardLayout from "../../layout/DashboardLayout";
import { LoadingScreen } from "../../components/loading-screen";
const ColoniesView = React.lazy(() => import('../../pages/colonies/colonies-view'));
const ProductsView = React.lazy(() => import('../../pages/products/products-view'));
const UserOrdersView = React.lazy(() => import('../../pages/userOrders/orders-view'));
const CustomersView = React.lazy(() => import('../../pages/customers/customers-view'));
const CategoriesView = React.lazy(() => import('../../pages/categories/categories-view'));

export const dashboardRoutes = [
  {
    path: '/panel',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <CategoriesView />, index: true },
      { path: 'user-orders', element: <UserOrdersView /> },
      {
        path: 'categories',
        children: [
          { element: <CategoriesView />, index: true },
          { path: ':categoryId/products', element: <ProductsView /> },
        ],
      },
      { path: 'colonies', element: <ColoniesView /> },
      { path: 'customers', element: <CustomersView /> },
    ],
  },
];
