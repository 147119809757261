import React from "react";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { IProduct, ICategory } from "../interfaces";
import { RootState, AppDispatch } from "../redux/store";
import useAuthentication, { IAuthHook } from "./useAuthentication";
import { readAllCategories, readProductsByCategory } from "../redux/actions";

interface MonteflorContextProps extends IAuthHook {
  isLoading: boolean;
  categories: ICategory[];
  products: IProduct[];
  fetchCategories: () => void;
  fetchProductsByCategory: (categoryId: string) => void;
}

/* eslint-disable */
interface MonteflorProviderProps {
  children: React.ReactNode
}

const MyContext = React.createContext({
  isLoading: false,
  categories: [] as ICategory[],
  fetchCategories: () => {},
} as MonteflorContextProps);

const MonteflorProvider: React.FC<MonteflorProviderProps> = ({ children }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const { isLoading: userAuthenticatedIsLoading, ...authentication } = useAuthentication()

  const { ReadCategories, ReadProductsByCategory } = useSelector((state: RootState) => ({
    ReadCategories: state.ReadCategories,
    ReadProductsByCategory: state.ReadProductsByCategory
  }));

  React.useEffect(() => {
    (async() => {

      setIsLoading(true)

      await authentication.getAuthState()

      await fetchCategories()

    })()
  }, [])

  React.useEffect(() => {
    setIsLoading(userAuthenticatedIsLoading || ReadCategories.isFetching)
  }, [userAuthenticatedIsLoading, ReadCategories])

  const fetchCategories = async () => {

    // @ts-ignore
    dispatch(readAllCategories());
  }

  const fetchProductsByCategory = async (categoryId: string) => {

    // @ts-ignore
    dispatch(readProductsByCategory(categoryId));
  }

  const memoizedValue = React.useMemo(() => {
    return {
      products: ReadProductsByCategory.products as IProduct[],
      categories: ReadCategories.categories as ICategory[],
      isLoading,
    };
  }, [isLoading, ReadCategories, ReadProductsByCategory]);

  return (
    <MyContext.Provider value={{
      ...memoizedValue,
      ...authentication,
      fetchCategories,
      fetchProductsByCategory
    }}>
      <ToastContainer theme={'colored'} closeOnClick={false} position={'top-center'} autoClose={3000} hideProgressBar />

      {children}
    </MyContext.Provider>
  );
}

const useMonteflor = () => {
  const context = React.useContext(MyContext);

  if (!context) {
    throw new Error('useMonteflor must be used within a MonteflorProvider');
  }

  return context;

}

export { MonteflorProvider, useMonteflor };
export default MonteflorProvider;
