import {
  CREATE_COLONY,
  READ_COLONIES,
  UPDATE_COLONY,

  DELETE_COLONY,
  CREATE_COLONY_FAILED,
  READ_COLONIES_FAILED,

  UPDATE_COLONY_FAILED,
  DELETE_COLONY_FAILED,
  CREATE_COLONY_SUCCESS,

  READ_COLONIES_SUCCESS,
  UPDATE_COLONY_SUCCESS,
  DELETE_COLONY_SUCCESS
} from '../../constants/actionTypes';

export const createColony = payload => ({ payload, type: CREATE_COLONY });
export const createColonyFailed = payload => ({ payload, type: CREATE_COLONY_FAILED });
export const createColonySuccess = payload => ({ payload, type: CREATE_COLONY_SUCCESS });

export const readColonies = payload => ({ payload, type: READ_COLONIES });
export const readColoniesFailed = payload => ({ payload, type: READ_COLONIES_FAILED });
export const readColoniesSuccess = payload => ({ payload, type: READ_COLONIES_SUCCESS });

export const updateColony = (_id, payload) => ({ _id, payload, type: UPDATE_COLONY });
export const updateColonyFailed = payload => ({ payload, type: UPDATE_COLONY_FAILED });
export const updateColonySuccess = payload => ({ payload, type: UPDATE_COLONY_SUCCESS });

export const deleteColony = (_id, payload) => ({ _id, payload, type: DELETE_COLONY });
export const deleteColonyFailed = payload => ({ payload, type: DELETE_COLONY_FAILED });
export const deleteColonySuccess = payload => ({ payload, type: DELETE_COLONY_SUCCESS });
