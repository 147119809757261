import { ICategory } from "../../interfaces";
import { ICategoryAction, CategoryActionTypes } from './constants';

export const readAllCategories = (request?: Partial<ICategory>): ICategoryAction => ({
  request,
  type: CategoryActionTypes.READ_ALL_CATEGORIES,
});
export const readAllCategoriesSuccess = (response: ICategory): ICategoryAction => ({
  response,
  type: CategoryActionTypes.READ_ALL_CATEGORIES_SUCCESS,
});
export const readAllCategoriesFailed = (response: string): ICategoryAction => ({
  response,
  type: CategoryActionTypes.READ_ALL_CATEGORIES_FAILED
});
