const ROOTS = {
  AUTH: '/auth',
  PANEL: '/panel',
};

// ----------------------------------------------------------------------

export const paths = {
  auth: {
    login: `${ROOTS.AUTH}/login`,
    logout: `${ROOTS.AUTH}/logout`,
    verify: `${ROOTS.AUTH}/verify`,
    register: `${ROOTS.AUTH}/register`,
    newPassword: `${ROOTS.AUTH}/new-password`,
    forgotPassword: `${ROOTS.AUTH}/forgot-password`,
  },
  panel: {
    root: `${ROOTS.PANEL}`,
    categories: {
      root: `${ROOTS.PANEL}/categories`,
      products: (categoryID: string) => `${ROOTS.PANEL}/categories/${categoryID}/products`
    },
    userOrders: `${ROOTS.PANEL}/user-orders`,
  }
};
