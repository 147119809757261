/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';

export const LOGOUT_USER = 'LOGOUT_USER';

export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';

/* RECUPERAR CONTRASEÑA DE USUARIO DE APP */
export const GET_USER_INFORMATION = 'GET_USER_INFORMATION';
export const GET_USER_INFORMATION_FAILED = 'GET_USER_INFORMATION_FAILED';
export const GET_USER_INFORMATION_SUCCESS = 'GET_USER_INFORMATION_SUCCESS';

/* RECUPERAR CONTRASEÑA */
export const RECOVERY_USER_PASSWORD = 'RECOVERY_USER_PASSWORD';
export const RECOVERY_USER_PASSWORD_FAILED = 'RECOVERY_USER_PASSWORD_FAILED';
export const RECOVERY_USER_PASSWORD_SUCCESS = 'RECOVERY_USER_PASSWORD_SUCCESS';

export const RECOVERY_USER_PASSWORD_DECRYPT = 'RECOVERY_USER_PASSWORD_DECRYPT';
export const RECOVERY_USER_PASSWORD_DECRYPT_FAILED = 'RECOVERY_USER_PASSWORD_DECRYPT_FAILED';
export const RECOVERY_USER_PASSWORD_DECRYPT_SUCCESS = 'RECOVERY_USER_PASSWORD_DECRYPT_SUCCESS';

export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const UPDATE_USER_PASSWORD_FAILED = 'UPDATE_USER_PASSWORD_FAILED';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';

/** COLONIAS **/
export const CREATE_COLONY = 'CREATE_COLONY';
export const CREATE_COLONY_FAILED = 'CREATE_COLONY_FAILED';
export const CREATE_COLONY_SUCCESS = 'CREATE_COLONY_SUCCESS';

export const READ_COLONIES = 'READ_COLONIES';
export const READ_COLONIES_FAILED = 'READ_COLONIES_FAILED';
export const READ_COLONIES_SUCCESS = 'READ_COLONIES_SUCCESS';

export const UPDATE_COLONY = 'UPDATE_COLONY';
export const UPDATE_COLONY_FAILED = 'UPDATE_COLONY_FAILED';
export const UPDATE_COLONY_SUCCESS = 'UPDATE_COLONY_SUCCESS';

export const DELETE_COLONY = 'DELETE_COLONY';
export const DELETE_COLONY_FAILED = 'DELETE_COLONY_FAILED';
export const DELETE_COLONY_SUCCESS = 'DELETE_COLONY_SUCCESS';

/* USER ORDERS */
export const READ_ALL_USER_ORDERS = 'READ_ALL_USER_ORDERS';
export const READ_ALL_USER_ORDERS_FAILED = 'READ_ALL_USER_ORDERS_FAILED';
export const READ_ALL_USER_ORDERS_SUCCESS = 'READ_ALL_USER_ORDERS_SUCCESS';

export const UPDATE_USER_ORDER = 'UPDATE_USER_ORDER';
export const UPDATE_USER_ORDER_FAILED = 'UPDATE_USER_ORDER_FAILED';
export const UPDATE_USER_ORDER_SUCCESS = 'UPDATE_USER_ORDER_SUCCESS';

/** ACCOUNTS **/
export const CREATE_ACCOUNT = 'REGISTER_ACCOUNT';
export const CREATE_ACCOUNT_FAILED = 'REGISTER_ACCOUNT_FAILED';
export const CREATE_ACCOUNT_SUCCESS = 'REGISTER_ACCOUNT_SUCCESS';

export const READ_ALL_ACCOUNTS = 'READ_ALL_ACCOUNTS';
export const READ_ALL_ACCOUNTS_FAILED = 'READ_ALL_ACCOUNTS_FAILED';
export const READ_ALL_ACCOUNTS_SUCCESS = 'READ_ALL_ACCOUNTS_SUCCESS';

/** USERS **/
export const READ_ALL_USERS = 'READ_ALL_USERS';
export const READ_ALL_USERS_FAILED = 'READ_ALL_USERS_FAILED';
export const READ_ALL_USERS_SUCCESS = 'READ_ALL_USERS_SUCCESS';
