import {
  UPDATE_USER_ORDER,
  READ_ALL_USER_ORDERS,
  UPDATE_USER_ORDER_FAILED,

  UPDATE_USER_ORDER_SUCCESS,
  READ_ALL_USER_ORDERS_FAILED,
  READ_ALL_USER_ORDERS_SUCCESS
} from '../../constants/actionTypes';

const INIT_READ_ALL_USER_ORDERS_STATE = {
  loading: false,
  error: undefined,
  userOrders: undefined,
};

const INIT_UPDATE_USER_ORDERS_STATE = {
  loading: false,
  error: undefined,
  userOrder: undefined,
};

export const ReadAllUserOrders = (state = INIT_READ_ALL_USER_ORDERS_STATE, { type, payload }) => {
  switch (type) {
    case READ_ALL_USER_ORDERS:
      return {
        loading: true,
        error: undefined,
        userOrders: undefined
      };
    case READ_ALL_USER_ORDERS_FAILED:
      return {
        loading: false,
        error: payload,
        userOrders: undefined
      };
    case READ_ALL_USER_ORDERS_SUCCESS:
      return {
        loading: false,
        error: undefined,
        userOrders: payload,
      };
    default:
      return { ...state };
  }
};
export const UpdateUserOrder = (state = INIT_UPDATE_USER_ORDERS_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_USER_ORDER:
      return {
        loading: true,
        error: undefined,
        userOrder: undefined
      };
    case UPDATE_USER_ORDER_FAILED:
      return {
        loading: false,
        error: payload,
        userOrder: undefined
      };
    case UPDATE_USER_ORDER_SUCCESS:
      return {
        loading: false,
        error: undefined,
        userOrder: payload,
      };
    default:
      return { ...state };
  }
};
