import React from "react";
import { Navigate, useRoutes } from 'react-router-dom';

import { authRoutes } from "./auth";
import { mainRoutes } from "./main";
import { dashboardRoutes } from './dashboard';
import DefaultLayout from "../../layout/DefaultLayout";
const HomePage = React.lazy(() => import('../../pages/landing/faq'));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <DefaultLayout>
          <HomePage />
        </DefaultLayout>
      ),
    },
    ...authRoutes,
    ...dashboardRoutes,
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
]);
}
