import { all, put, call, fork, takeEvery } from 'redux-saga/effects';

import fetchJSON from '../../helpers/external-request';
import {
  UPDATE_USER_ORDER,
  READ_ALL_USER_ORDERS,
} from '../../constants/actionTypes';
import {
  updateUserOrderFailed,
  updateUserOrderSuccess,
  readAllUserOrdersFailed,
  readAllUserOrdersSuccess
} from './actions';

function* updateUserOrder({ orderID, payload: data }) {
  const options = {
    data,
    method: 'PUT',
  };

  try {
    const response = yield call(fetchJSON, `orders/${orderID}`, options);
    yield put(updateUserOrderSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }
    yield put(updateUserOrderFailed(message));
  }
}

function* readAllUserOrders({ payload: params }) {
  const options = {
    params,
    method: 'GET',
  };

  try {
    const response = yield call(fetchJSON, 'orders', options);
    yield put(readAllUserOrdersSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error.message;
    }
    yield put(readAllUserOrdersFailed(message));
  }
}

export function* watchUpdateUserOrder(): any {
  yield takeEvery(UPDATE_USER_ORDER, updateUserOrder);
}

export function* watchReadAllUserOrders(): any {
  yield takeEvery(READ_ALL_USER_ORDERS, readAllUserOrders);
}

function* userOrdersSaga(): any {
  yield all([
    fork(watchUpdateUserOrder),
    fork(watchReadAllUserOrders),
  ]);
}

export default userOrdersSaga;
