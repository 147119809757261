import {
  LOGIN_USER,

  LOGOUT_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESS,

  GET_USER_INFORMATION,
  UPDATE_USER_PASSWORD,
  GET_USER_INFORMATION_FAILED,

  UPDATE_USER_PASSWORD_FAILED,
  GET_USER_INFORMATION_SUCCESS,
  UPDATE_USER_PASSWORD_SUCCESS
} from '../../constants/actionTypes';

export const loginUser = payload => ({
  payload,
  type: LOGIN_USER,
});
export const loginUserSuccess = payload => ({
  payload,
  type: LOGIN_USER_SUCCESS
});
export const loginUserFailed = payload => ({
  payload,
  type: LOGIN_USER_FAILED
});

export const getUserInformation = hash => ({
  hash,
  type: GET_USER_INFORMATION
});
export const getUserInformationSuccess = user => ({
  user,
  type: GET_USER_INFORMATION_SUCCESS
});
export const getUserInformationFailed = error => ({
  error,
  type: GET_USER_INFORMATION_FAILED
});

export const updateUserPassword = (hash, payload) => ({
  hash,
  payload,
  type: UPDATE_USER_PASSWORD
});
export const updateUserPasswordSuccess = message => ({
  message,
  type: UPDATE_USER_PASSWORD_SUCCESS
});
export const updateUserPasswordFailed = error => ({
  error,
  type: UPDATE_USER_PASSWORD_FAILED
});

export const logoutUser = payload => ({
  payload,
  type: LOGOUT_USER,
});
