import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { GuestGuard } from '../guards';
import DefaultLayout from '../../layout/DefaultLayout';
import { LoadingScreen } from "../../components/loading-screen";

// ----------------------------------------------------------------------

const LoginPage = React.lazy(() => import("../../pages/auth/Login"));
const LogoutPage = React.lazy(() => import("../../pages/auth/Logout"));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <DefaultLayout>
              <LoginPage />
            </DefaultLayout>
          </GuestGuard>
        ),
      },
      {
        path: 'logout',
        element: (
          <GuestGuard>
            <DefaultLayout>
              <LogoutPage />
            </DefaultLayout>
          </GuestGuard>
        ),
      },
    ],
  },
];
