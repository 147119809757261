import createSagaMiddleware from 'redux-saga';
import { EnhancedStore, configureStore } from '@reduxjs/toolkit';

import rootSaga from './sagas';
import rootReduces from './reducers';

let store: EnhancedStore
const sagaMiddleware = createSagaMiddleware();

export function configureAppStore(preloadedState?: {}) {
  store = configureStore({
    preloadedState,
    reducer: rootReduces,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
  });

  sagaMiddleware.run(rootSaga);

  return store;
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
